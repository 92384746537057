






import Vue from 'vue';

export default Vue.extend({
  name: "PostOfficeMain2d",
  data: () => {
    return {};
  },
});
